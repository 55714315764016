<template functional>
  <router-link
    :class="{ 'our-works__item': props.isOurWork }"
    :to="{ name: 'work-details', params: { slug: props.item.slug } }"
  >
    <section class="project__img-bg p-3 ">
      <div class="img-wrap  ratio-75 text-sm-center">
        <div class="img-content">
          <b-img fluid :src="props.item.logo ? props.item.logo.path : ''"> </b-img>
        </div>
      </div>
      <h5 class="project__ourwork--title mb-0 mt-2">{{ props.item.name }}</h5>
    </section>
  </router-link>
</template>

<script>
export default {
  name: "OurWorkItem",
  inheritAttrs: false,
  props: {
    item: {
      type: Object,
      required: true
    },
    isOurWork: {
      type: Boolean,
      default: false
    }
  }
};
</script>
